import { Link } from "gatsby"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const PastPost = ({ data }) => {
    const getPostImage = (allImageEdges, slug) => {
        return allImageEdges.filter(edge => edge.node.base === slug + '.jpg');
    }

    return (
        <section className='mt-8 md:mt-12 mx-8 md:mx-auto md:w-1/2'>
            <h3 className='text-3xl text-center font-bold text-gray-800'>Past Essays</h3>
            <div className='flex flex-col mt-4 md:mt-12 space-y-10 md:space-y-16'>
                {data.allMarkdownRemark.edges.slice(1).map(({ node }) => (
                    <div key={node.id} className='md:flex text-center mx-auto bg-blueGray-100 shadow rounded'>
                        <GatsbyImage
                            image={getPostImage(data.allImages.edges, node.fields.slug.replace(/\//g, ''))[0].node.childImageSharp.gatsbyImageData}
                            alt='Blog post thumbnail image'
                            className='md:w-3/5 h-40 md:h-60' />
                        <div className='md:w-2/5 flex flex-col justify-center items-center p-4 text-left'>
                            <h2 className='text-2xl font-semibold text-center tracking-normal text-gray-700'>{node.frontmatter.title}</h2>
                            <p className='font-medium text-gray-700'>{node.frontmatter.date}</p>
                            <p className='mt-2 px-5 text-base text-gray-700 hidden md:block'>{node.excerpt}</p>
                            <Link to={node.fields.slug} className='bg-blueGray-500 text-white inline-flex mt-3 md:mt-4 rounded py-1 px-5'>Read</Link>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default PastPost;
