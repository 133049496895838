import { Link } from "gatsby"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const LatestPost = ({ data }) => {
    const getLatestimage = (allImageEdges, slug) => {
        return allImageEdges.filter(edge => edge.node.base === slug + '.jpg');
    }

    const latestPost = data.allMarkdownRemark.edges[0].node;
    const latestImage = getLatestimage(data.allImages.edges, latestPost.fields.slug.replace(/\//g, ''));

    return (
        <section className='flex flex-col mt-8 md:mt-12 mx-4 md:mx-auto md:w-2/3'>
            <h3 className='text-3xl font-bold text-center text-gray-800'>Latest Essay</h3>
            <div className='md:flex mt-4 md:mt-12 shadow rounded'>
                <GatsbyImage
                    image={latestImage[0].node.childImageSharp.gatsbyImageData}
                    alt='Latest blog post image'
                    className='md:w-3/5' />
                <div className='md:w-2/5 flex flex-col justify-center items-center md:p-10 text-left'>
                    <h2 className='text-2xl md:text-3xl font-semibold text-center tracking-normal text-gray-700'>{latestPost.frontmatter.title}</h2>
                    <p className='font-medium text-gray-700 md:mt-2'>{latestPost.frontmatter.date}</p>
                    <p className='mt-2 px-5 text-base md:text-lg text-gray-700'>{latestPost.excerpt}</p>
                    <Link to={latestPost.fields.slug} className='rounded bg-blueGray-500 text-white inline-flex mt-2 md:mt-4 py-1 px-5 mb-5'>Read</Link>
                </div>
            </div>
        </section >
    )
}

export default LatestPost;
