import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LatestPost from '../components/latest_post'
import PastPost from '../components/past_post'

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Techimadions | Meditations in technology" />
    <LatestPost data={data} />
    <PastPost data={data} />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
    ) 
    {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            rawDate: date(formatString: "DD-MM-YYYY")
          }
          fields {
            slug
          }
          excerpt(
            format: PLAIN
            pruneLength: 200
            truncate: true
          )
        }
      }
    }
    allImages: allFile(filter: {extension: {eq: "jpg"}}) {
      edges {
        node {
          base
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
